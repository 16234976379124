<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body>
      <div class="table-header">
        <h4 class="card-title">Permission</h4>
      </div>

      <div class=" permission-view py-2 d-flex justify-content-between px-2">
        <div class="custom-control custom-checkbox d-flex">
          <b-form-checkbox v-model="selected" value="" />
          <div class="ml-1">Add Source</div>
        </div>

        <div class="custom-control custom-checkbox d-flex">
          <b-form-checkbox v-model="selected" value="" />
          <div class="ml-1">Add Source</div>
        </div>
        <div class="custom-control custom-checkbox d-flex">
          <b-form-checkbox v-model="selected" value="" />
          <div class="ml-1">Add Source</div>
        </div>
        <div class="custom-control custom-checkbox d-flex">
          <b-form-checkbox v-model="selected" value="" />
          <div class="ml-1">Add Source</div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BTab,
  BCardText,
  BFormDatepicker,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardText,
    BTabs,
    BTab,
    vSelect,
    BFormDatepicker,
    BFormSelect,
    BFormCheckbox,
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Simple Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.header-title {
  height: 60px;
  border-bottom: 1px solid #ebe9f1;
  font-weight: 500;
  color: #636363;
  font-size: 1.714rem;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.permission-view{
  height:300px;
  overflow: auto
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
